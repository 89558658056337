.op-body-servicios {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 100px);
  overflow: hidden;
}

.op-section.op-servicios {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: calc(100vh - 100px);
  width: 100%;
}

.op-section.op-servicios.on {
  height: 130vh;
}

.op-section.op-servicios .op-slide-serv {
  position: relative;
  -webkit-box-flex: 1;
  flex: 1;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
  transition: all 1s;
}

.op-section.op-servicios .op-slide-serv.active {
  cursor: default;
}

/*Imagen*/
.op-section.op-servicios .op-slide-serv .op-image {
  position: absolute;
  background-size: cover;
  background-position: center center;
  pointer-events: none;
  transition: all 1s;
  -webkit-transition: all 1s;
  opacity: 0.8;
}

.op-section.op-servicios .op-slide-serv:hover:not(.active) .op-image {
  transform: scale(1.2);
}

.op-section.op-servicios .op-slide-serv.active .op-image {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.5;
}

.op-section.op-servicios .op-slide-serv.op-anim-in {
  transition-delay: 0.5s;
}

.op-section.op-servicios .op-slide-serv.op-anim-in .op-image {
  top: -20%;
  left: 0;
  height: 140%;
  width: 140%;
  animation: img-anim-in 1.2s;
}

.op-section.op-servicios .op-slide-serv.op-anim-out {
  flex: 0;
  cursor: default;
  transition-delay: 0.5s;
}

.op-section.op-servicios .op-slide-serv.op-anim-out .op-image {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  animation: img-anim-out 1.2s;
}

.op-section.op-servicios .op-slide-serv .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(
    rgba(21, 194, 161, 0.7),
    rgba(21, 194, 161, 0.7)
  );
  background-position: 0 0;
  background-size: 100% 200%;
  opacity: 1;
  pointer-events: none;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.op-section.op-servicios .op-slide-serv .content {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
}

/* TITULO */

.op-section.op-servicios .op-slide-serv .content .title {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  font-size: 22px;
  text-align: center;
  text-shadow: 0 2px 2px #2f2f2f;
  color: #ffffff;
  opacity: 1;
  pointer-events: none;
  transition: all 0.5s;
}

.op-section.op-servicios .op-slide-serv .content .title::after {
  position: absolute;
  top: 0;
  height: 0;
  width: 100%;
  display: block;
  content: attr(data-title);
  overflow: hidden;
  color: #ffffff;
  transition: all 0.85s;
}

.op-section.op-servicios .op-slide-serv .content .title::before {
  position: absolute;
  display: block;
  content: '';
  height: 2px;
  width: 60%;
  background: #15c2a1;
  transform-origin: left center;
  transform: scaleX(0);
  transition: transform 1.25s;
  left: 0;
  bottom: -10px;
}

/*ACTIVO*/
.op-section.op-servicios .op-slide-serv:hover:not(.active) .content .title {
  transform: translateY(30px);
}

.op-section.op-servicios .op-slide-serv:hover:not(.active) .overlay {
  background-image: none;
}

.op-section.op-servicios .op-slide-serv.active .content .title {
  width: 100%;
  opacity: 1;
  transform: translateY(0px);
  transition: all 1s;
  transition-delay: 1.25s;
  height: auto;
  font-size: 40px;
  color: #005d96;
  text-transform: uppercase;
  text-shadow: none;
  text-align: left;
  padding: 0 100px;
  justify-content: flex-start;
  align-items: flex-start;
}

.op-section.op-servicios .op-slide-serv.active .content .title::after {
  height: 100%;
  color: #ffffff;
  overflow: initial;
}

.op-section.op-servicios .op-slide-serv.active .content .title::before {
  transform: scaleX(1);
  transition-delay: 2s;
}

.op-section.op-servicios .op-slide-serv.active .overlay {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0)
  );
  transition-delay: 1.8s;
}

/* Close*/
.op-section.op-servicios .op-slide-serv.active .btn-close {
  cursor: pointer;
  pointer-events: auto;
}

.op-section.op-servicios .op-slide-serv.active .btn-close::before,
.op-section.op-servicios .op-slide-serv.active .btn-close::after {
  opacity: 1;
}

.op-section.op-servicios .op-slide-serv.active .btn-close::after {
  transform: rotate(45deg) translateX(0);
}

.op-section.op-servicios .op-slide-serv.active .btn-close::before {
  transform: rotate(-45deg) translateX(0);
}

.op-section.op-servicios .op-slide-serv .btn-close {
  position: absolute;
  z-index: 100;
  top: 20px;
  right: 100px;
  height: 24px;
  width: 24px;
  pointer-events: none;
}

.op-section.op-servicios .op-slide-serv .btn-close::before,
.op-section.op-servicios .op-slide-serv .btn-close::after {
  position: absolute;
  top: 12px;
  display: block;
  content: '';
  width: 100%;
  height: 4px;
  background-color: #005d96;
  opacity: 0;
  cursor: pointer;
  transition: all 0.5s;
}

.op-section.op-servicios .op-slide-serv .btn-close::after {
  transform: rotate(45deg) translateX(-12px);
  transition-delay: 3s;
}

.op-section.op-servicios .op-slide-serv .btn-close::before {
  transform: rotate(-45deg) translateX(12px);
  transition-delay: 3.2s;
}

.op-section.op-servicios .op-slide-serv .content .images {
  width: 60%;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

.op-section.op-servicios .op-slide-serv .content .images .bloque {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 100px;
}

/*Emblema*/
.op-section.op-servicios .op-slide-serv .content .imagen-circular {
  position: relative;
  transform: translateY(120px);
  transition: all 1.75s;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  height: 0;
  opacity: 0;
}

.op-section.op-servicios .op-slide-serv .content .emblema {
  height: 15vh;
  width: 100%;
  bottom: 150px;
  opacity: 0;
  position: absolute;
  transition: transform 1.25s;
}

.op-section.op-servicios .op-slide-serv:hover:not(.active) .content .emblema {
  transform: translateY(140px);
}

.op-section.op-servicios .op-slide-serv.active .content .emblema {
  transform: translateY(10px);
  width: 25%;
  height: 15vh;
  position: relative;
  bottom: 0;
  opacity: 0;
  display: none;
}

.op-section.op-servicios .op-slide-serv .content .imagen-01 {
  opacity: 0;
}

.op-section.op-servicios .op-slide-serv.active .content .imagen-01 {
  position: absolute;
  transform: translateY(0px);
  height: 70%;
  width: 35%;
  right: 75px;
  top: 15%;
  opacity: 1;
  transition-delay: 2.5s;
}

.op-section.op-servicios .op-slide-serv.active .content .imagen-02,
.op-section.op-servicios .op-slide-serv.active .content .imagen-03 {
  transform: translateY(0px);
  height: 28vh;
  width: 35%;
  opacity: 1;
  transition-delay: 3s;
}

/*Informacion*/
.op-section.op-servicios .op-slide-serv .content .info {
  position: relative;
  width: 50%;
  padding: 0 0 0 100px;
  font-size: 16px;
  color: #000000;
  opacity: 0;
  transform-origin: left center;
  transform: scaleX(0);
  transition: transform 1.5s;
  transition: opacity 1s;
}

.op-section.op-servicios .op-slide-serv.active .content .info {
  opacity: 1;
  transform: scaleX(1);
  transition-delay: 2s;
  text-align: justify;
}

.op-section.op-servicios .op-slide-serv .content .subtitle {
  padding: 30px;
  font-size: 20px;
  color: #005d96;
  opacity: 0;
  transform-origin: left center;
  transform: scaleX(0);
  transition: transform 2s;
  transition: opacity 1.5s;
}

.op-section.op-servicios .op-slide-serv.active .content .subtitle {
  opacity: 1;
  transform: scaleX(1);
  transition-delay: 2.5s;
}

@keyframes img-anim-in {
  to {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

@keyframes img-anim-out {
  to {
    left: -100%;
  }
}

@media (min-width: 1350px) {
  .op-section.op-servicios .op-slide-serv.active .content .info {
    font-size: 20px;
  }

  .op-section.op-servicios .op-slide-serv.active .content .subtitle {
    font-size: 24px;
  }

  .op-section.op-servicios .op-slide-serv.active .content .imagen-02,
  .op-section.op-servicios .op-slide-serv.active .content .imagen-03 {
    height: 35vh;
  }
}

@media (max-width: 1158px) {
  .op-section.op-servicios .op-slide-serv.active .content .title {
    font-size: 34px;
  }

  .op-section.op-servicios .op-slide-serv.active .content .info {
    width: 47%;
    font-size: 15px;
  }

  .op-section.op-servicios .op-slide-serv .content .images {
    padding-top: 10px;
  }

  .op-section.op-servicios .op-slide-serv.active .content .imagen-02,
  .op-section.op-servicios .op-slide-serv.active .content .imagen-03 {
    height: 26vh;
  }
}

@media (max-width: 858px) {
  .op-section.op-servicios {
    height: calc(100vh - 110px);
  }

  .op-section.op-servicios.on {
    height: 300vh;
  }

  .op-section.op-servicios .op-slide-serv {
    flex: 0 1 33.33%;
    height: 50%;
  }

  .op-section.op-servicios .op-slide-serv.active {
    flex: 1;
    height: 100%;
  }

  .op-section.op-servicios .op-slide-serv.active .content {
    display: flex;
    flex-direction: column;
  }

  .op-section.op-servicios .op-slide-serv.active .content .title {
    position: relative;
    flex: 0 1;
    margin-bottom: 37px;
    padding: 30px 50px 0;
    width: 100%;
  }

  .op-section.op-servicios .op-slide-serv:not(.active) .content .title {
    font-size: 18px;
    padding: 0 20px;
  }

  .op-section.op-servicios .op-slide-serv.active .content .title::before {
    width: 100%;
    bottom: -10px;
  }

  .op-section.op-servicios .op-slide-serv.active .content .info,
  .op-section.op-servicios .op-slide-serv.active .content .subtitle {
    position: relative;
    top: 0;
    left: 0;
    padding: 0 50px 40px;
    width: 100%;
  }

  .op-section.op-servicios .op-slide-serv.active .content .info {
    width: 85%;
  }

  .op-section.op-servicios .op-slide-serv.active .content .images {
    width: 100%;
  }

  .op-section.op-servicios .op-slide-serv.active .content .images .bloque {
    padding: 0 50px;
    align-items: center;
  }

  .op-section.op-servicios .op-slide-serv.active .content .imagen-circular {
    position: relative;
    transform: translateY(0);
    width: 100%;
    top: 0;
    left: 0;
  }

  .op-section.op-servicios
    .op-slide-serv.active
    .content
    .imagen-circular.emblema {
    height: 232px;
  }

  .op-section.op-servicios .op-slide-serv.active .content .imagen-01 {
    height: 674px;
    margin-bottom: 36px;
  }

  .op-section.op-servicios .op-slide-serv.active .content .imagen-02,
  .op-section.op-servicios .op-slide-serv.active .content .imagen-03 {
    height: 320px;
  }

  .op-section.op-servicios .op-slide-serv.active .content .imagen-02 {
    background-position-x: left;
  }

  .op-section.op-servicios .op-slide-serv.active .content .imagen-03 {
    background-position-x: right;
  }

  .op-section.op-servicios .op-slide-serv .btn-close {
    right: 50px;
  }
}

@media (max-width: 576px) {
  .op-section.op-servicios {
    height: calc(270vh - 110px);
  }

  .op-section.op-servicios .op-slide-serv {
    flex: 0 1 100%;
    height: 16.6%;
  }

  .op-section.op-servicios .op-slide-serv.active .content .title {
    font-size: 26px;
    padding: 30px 30px 0;
  }

  .op-section.op-servicios .op-slide-serv:not(.active) .content .title {
    font-size: 22px;
  }

  .op-section.op-servicios .op-slide-serv.active .content .title::before {
    width: calc(100% - 30px);
  }

  .op-section.op-servicios .op-slide-serv.active .content .subtitle {
    font-size: 18px;
    width: 90%;
    padding-top: 30px;
  }

  .op-section.op-servicios .op-slide-serv.active .content .info {
    padding: 0 30px 30px;
  }

  .op-section.op-servicios .op-slide-serv.active .content .images .bloque {
    flex-direction: column;
  }

  .op-section.op-servicios .op-slide-serv.active .content .imagen-circular {
    height: 286px;
    margin-bottom: 36px;
    background-position-x: center;
  }

  .op-section.op-servicios .op-slide-serv.active .content .imagen-01 {
    height: 330px;
  }

  .op-section.op-servicios .op-slide-serv .content .emblema {
    opacity: 0;
  }

  .op-section.op-servicios
    .op-slide-serv.active
    .content
    .imagen-circular.emblema {
    height: 197px;
    margin: 0;
  }

  .op-section.op-servicios .op-slide-serv .btn-close {
    right: 30px;
  }
}
