.op-panel.start {
  display: grid;
  position: relative;
  width: 100%;
  min-height: calc(100vh - 100px);
  grid-template-columns: 40% 60%;
  grid-template-rows: 1fr 30px;
  grid-template-areas:
    'info image'
    'contact contact';
}

.op-panel.start .contact {
  grid-area: contact;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: relative;
}

.op-panel.start .contact label {
  text-transform: uppercase;
  font-size: calc(1rem + min(2px, 5vw));
  font-weight: bolder;
  font-style: oblique;
  color: #fff;
  -webkit-text-stroke: 2px #6d6767;
  text-shadow: 1px 1px #6d6767;
  paint-order: stroke fill;
}

.op-panel.start .contact .icon-list::before {
  content: '';
  display: block;
  width: 35px;
  height: 35px;
  background-size: cover;
  background-position: center top;
  background-image: url('../assets/clipboard-list-outline.svg');
}

.op-panel.start .image {
  grid-area: image;
  background-size: cover;
  background-position: center top;
  background-image: url('../assets/Inicio/imagen-6.jpeg');
}
.op-panel.start .info {
  position: relative;
  grid-area: info;
  display: flex;
  justify-content: flex-end;
  align-items: self-start;
  flex-direction: column;
  padding: 20px 0 20px 150px;
}

.op-panel.start .info::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-size: cover;
  background-position: left;
  background-image: url('../assets/Inicio/imagen-5.jpeg');
  opacity: 0.6;
  z-index: -2;
}

.op-panel.start .info h2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  text-transform: uppercase;
  font-size: calc(1rem + min(30px, 5vw));
  font-weight: bolder;
  font-style: oblique;
  color: #005d96;
  -webkit-text-stroke: 5px #fff;
  text-shadow: 3px 3px #fff;
  margin: 5px 0;
  paint-order: stroke fill;
}

.op-panel.start .info h2:first-child {
  color: #fff;
  -webkit-text-stroke: 5px #6d6767;
  text-shadow: 4px 4px #6d6767;
}

.op-panel.start .info h2 > div {
  display: flex;
  gap: 20px;
}

.op-panel.start .info p {
  color: #fff;
  font-weight: bolder;
  letter-spacing: 1px;
  font-size: 16px;
  -webkit-text-stroke: 2px #c8c8c8;
  paint-order: stroke fill;
}

.op-panel.start .info label {
  align-self: center;
  background-color: orangered;
  padding: 5px;
  border-radius: 5px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bolder;
}

.op-panel.start .info .whatsapp {
  align-self: center;
  bottom: 50px;
  right: 5px;
  width: 180px;
  height: 80px;
  background-image: url('../assets/wasapflotante.svg');
  background-position: center;
  background-repeat: no-repeat;
  text-decoration: none;
}

.op-panel.start .info .whatsapp::before {
  content: '947 040 968';
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: #fff 5px solid;
  border-radius: 40px;
  padding-right: 8px;
  width: 170px;
  height: 40px;
  background-color: #2cb642;
  color: #fff;
  font-size: 20px;
  font-weight: bolder;
  position: relative;
  left: 5px;
  top: 15px;
  z-index: -1;
}

@media (max-width: 858px) {
  .op-panel.start {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 80px;
    grid-template-areas:
      'info'
      'image'
      'contact';
  }
}

@media (max-width: 576px) {
  .op-panel.start .contact {
    flex-direction: column;
    padding: 0 30px;
  }

  .op-panel.start .contact label {
    text-align: center;
  }
}
