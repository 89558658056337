.op-footer {
  position: relative;
  width: 100%;
  min-height: 70px;
  bottom: -99px;
  background-color: #005d96;
  display: flex;
  color: #ffffff;
  border-top: 1px solid #94c12e;
  justify-content: center;
}

.op-footer .op-bloque {
  display: flex;
  justify-content: center;
  align-items: center;
}

.op-footer .op-bloque span {
  padding: 20px;
}

.op-footer .op-bloque a {
  padding: 20px;
  text-decoration: none;
  color: #ffffff;
  font-size: 18px;
}

.op-footer .op-bloque i {
  color: #8dbb38;
  padding: 20px 0 20px 20px;
}

@media (max-width: 858px) {
  .op-footer {
    flex-wrap: wrap;
  }
  .op-footer .op-bloque {
    flex: 0 1 50%;
    justify-content: flex-start;
  }

  .op-footer .op-bloque:nth-child(3) {
    order: 3;
  }
  .op-footer .op-bloque:nth-child(4) {
    order: 2;
  }
}

@media (max-width: 576px) {
  .op-footer {
    flex-direction: column;
  }

  .op-footer .op-bloque {
    order: 1 !important;
  }

  .op-footer .op-bloque i,
  .op-footer .op-bloque span {
    padding: 10px 20px;
  }

  .op-footer .op-bloque:last-child span {
    margin-left: 7px;
  }
}
