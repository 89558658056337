.op-body-galeria {
  position: relative;
  top: 101px;
  width: 100%;
  min-height: calc(100vh - 172px);
  overflow: hidden;
}

.op-body-galeria .op-galeria {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: calc(100vh - 172px);
}

.op-body-galeria .op-galeria .galeria-item {
  position: relative;
  flex: 0 1 25%;
  opacity: 0;
  animation-name: galeria-item-loader;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.op-body-galeria .op-galeria .galeria-item:nth-child(1) {
  animation-delay: 0.1s;
}

.op-body-galeria .op-galeria .galeria-item:nth-child(2) {
  animation-delay: 0.2s;
}

.op-body-galeria .op-galeria .galeria-item:nth-child(3) {
  animation-delay: 0.3s;
}

.op-body-galeria .op-galeria .galeria-item:nth-child(4) {
  animation-delay: 0.4s;
}

.op-body-galeria .op-galeria .galeria-item:nth-child(5) {
  animation-delay: 0.5s;
}

.op-body-galeria .op-galeria .galeria-item:nth-child(6) {
  animation-delay: 0.6s;
}

.op-body-galeria .op-galeria .galeria-item:nth-child(7) {
  animation-delay: 0.7s;
}

.op-body-galeria .op-galeria .galeria-item:nth-child(8) {
  animation-delay: 0.8s;
}

.op-body-galeria .op-galeria .galeria-item:nth-child(9) {
  animation-delay: 0.9s;
}

.op-body-galeria .op-galeria .galeria-item:nth-child(10) {
  animation-delay: 1s;
}

.op-body-galeria .op-galeria .galeria-item:nth-child(11) {
  animation-delay: 1.1s;
}

.op-body-galeria .op-galeria .galeria-item:nth-child(12) {
  animation-delay: 1.2s;
}

.op-body-galeria .op-galeria .galeria-item a {
  display: block;
  overflow: hidden;
}

.op-body-galeria .op-galeria .galeria-item img {
  width: 100%;
  vertical-align: top;
  height: calc((100vh - 172px) / 3);
  object-fit: cover;
  transition: transform 0.5s;
}

.op-body-galeria .op-galeria .galeria-item a:hover img {
  transform: scale(1.2);
}

.op-body-galeria .op-galeria .galeria-item .titulo {
  position: absolute;
  text-align: center;
  font-size: 22px;
  color: #ffffff;
  bottom: 10px;
  width: 100%;
  transition: all 0.5s;
}

.op-body-galeria .op-galeria .galeria-item:hover .titulo {
  font-size: 0;
}

.op-body-galeria .op-galeria .galeria-item .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(
    rgba(21, 194, 161, 0.7),
    rgba(21, 194, 161, 0.7)
  );
  background-position: 0 0;
  background-size: 100% 200%;
  opacity: 1;
  pointer-events: none;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.op-body-galeria .op-galeria .galeria-item:hover .overlay {
  background-image: none;
}

.op-body-galeria .op-modal .light-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  opacity: 0;
  transition: all 0.5s;
}

.op-body-galeria .op-modal .light-box:target {
  opacity: 1;
  /* background-color: rgba(21, 194, 161, 0.9); */
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1000;
}

.op-body-galeria .op-modal .light-box img {
  max-width: 100vw;
  max-height: 94vh;
  transform: scale(0);
  transition: transform 1s;
}

.op-body-galeria .op-modal .light-box:target img {
  transform: scale(1);
}

.op-body-galeria .op-modal .light-box span.titulo {
  flex: 0 1 100%;
  color: #ffffff;
  font-size: 26px;
  transition: all 1.5s;
  position: relative;
  left: 0;
}

.op-body-galeria .op-modal .light-box:target span.titulo {
  left: 40%;
}

.op-body-galeria .op-modal .light-box .close {
  position: absolute;
  top: 15px;
  right: 50px;
  height: 24px;
  width: 24px;
}

.op-body-galeria .op-modal .light-box .close::before,
.op-body-galeria .op-modal .light-box .close::after {
  position: absolute;
  top: 12px;
  display: block;
  content: '';
  width: 100%;
  height: 4px;
  background-color: #ffffff;
  opacity: 0;
  cursor: pointer;
  transition: all 0.5s;
}

.op-body-galeria .op-modal .light-box .close::before {
  transform: rotate(-45deg) translateX(12px);
  transition-delay: 2.2s;
}

.op-body-galeria .op-modal .light-box .close::after {
  transform: rotate(45deg) translateX(-12px);
  transition-delay: 2s;
}

.op-body-galeria .op-modal .light-box:target .close::before {
  transform: rotate(-45deg) translateX(0);
  opacity: 1;
}

.op-body-galeria .op-modal .light-box:target .close::after {
  transform: rotate(45deg) translateX(0);
  opacity: 1;
}

.op-body-galeria .op-modal .light-box .full {
  position: absolute;
  top: -30px;
  right: 90px;
  height: 24px;
  width: 24px;
  color: #ffffff;
  font-size: 26px;
  cursor: pointer;
  transition: all 2.5s;
  display: none;
}

.op-body-galeria .op-modal .light-box:target .full {
  top: 15px;
}

.op-body-galeria .op-modal .light-box .arrow {
  display: block;
  color: #ffffff;
}

.op-body-galeria .op-modal .light-box .arrow i:last-child {
  font-size: 40px;
}

.op-body-galeria .op-modal .light-box .arrow.prev {
  padding-left: 50px;
}

.op-body-galeria .op-modal .light-box .arrow.next {
  padding-right: 50px;
}

.op-body-galeria .op-modal .light-box .arrow.prev i:last-child::before {
  margin-right: 5px;
}

.op-body-galeria .op-modal .light-box .arrow.next i:last-child::before {
  margin-left: 5px;
}

@keyframes galeria-item-loader {
  0% {
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0 0);
    opacity: 1;
  }
}

@media (max-width: 858px) {
  .op-body-galeria .op-galeria {
    min-height: calc(100vh - 172px);
    height: auto;
  }

  .op-body-galeria .op-galeria .galeria-item {
    flex: 0 1 50%;
  }

  .op-body-galeria .op-modal .light-box img {
    max-width: 80%;
  }

  .op-body-galeria .op-modal .light-box:target span.titulo {
    left: 35%;
  }

  .op-body-galeria .op-modal .light-box .full {
    display: none;
  }

  .op-body-galeria .op-modal .light-box .arrow {
    padding: 0 !important;
  }

  .op-body-galeria .op-modal .light-box:target .close {
    top: 10px;
    right: 10px;
  }
}

@media (max-width: 576px) {
  .op-body-galeria .op-galeria .galeria-item {
    flex: 0 1 100%;
  }

  .op-body-galeria .op-modal .light-box:target {
    justify-content: center;
  }

  .op-body-galeria .op-modal .light-box img {
    max-width: 95%;
  }

  .op-body-galeria .op-modal .light-box:target span.titulo {
    left: 10px;
  }

  .op-body-galeria .op-modal .light-box .arrow {
    display: none;
  }
}
