@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

body {
  padding: 0;
  margin: 0;
  font-family: Lato;
}

html {
  scroll-behavior: smooth;
  scroll-padding-block-start: 100px;
}

.op-app {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100px 1fr;
  grid-template-areas:
    'header'
    'body';
}

.op-app .op-body {
  grid-area: body;
}

.op-bloque .op-bloque-head {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #94c12e;
  height: 40px;
}

.op-bloque input[type='checkbox'] {
  display: none;
}

.op-bloque .op-bloque-head label {
  font-size: 20px;
  color: #94c12e;
  cursor: pointer;
  animation: label-loader 500ms ease-in;
}

.op-bloque .op-bloque-head label span {
  height: 4px;
  width: 12px;
  background-color: #94c12e;
  display: block;
  margin: 5px 0 5px 0;
  transition: 0.5s ease-in-out;
}

.op-bloque .op-bloque-head label span:nth-of-type(1) {
  position: relative;
  top: 9px;
  opacity: 1;
}

.op-bloque .op-bloque-head label span:nth-of-type(2) {
  transform: rotate(90deg);
}

.op-bloque .op-bloque-content {
  transition: all 1s;
  max-height: 500px;
  opacity: 1;
}

.op-bloque
  input[type='checkbox']:checked
  ~ .op-bloque-head
  > label
  span:nth-of-type(2) {
  opacity: 0;
}

.op-bloque input[type='checkbox']:checked ~ .op-bloque-content {
  max-height: 0;
  opacity: 0;
}

.wasap-flotante {
  position: fixed;
  bottom: 50px;
  right: 5px;
  width: 180px;
  height: 80px;
  background-image: url('../assets/wasapflotante.svg');
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1000;
  transition: transform 0.5s;
  animation: flotante-wasap 1.5s ease-in;
}

.wasap-flotante:hover {
  transform: scale(1.2);
}

@keyframes label-loader {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes flotante-wasap {
  0% {
    bottom: 0;
    transform: scale(0);
  }
  100% {
    bottom: 50px;
    transform: scale(1);
  }
}
